<template>
    <div v-if='model && !model.is_null'>
        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-6'>
                    <div class='p-inputgroup'>
                        <Button :icon='companiesRefreshLoadingIcon' class='p-button-warning'
                                @click='refreshCompanies' />
                        <span class='p-float-label'>
                            <Dropdown
                                v-model='v$.model.company_id.$model'
                                :class="{'p-invalid': v$.model.company_id.$errors.length}"
                                id='company_id'
                                dataKey='id'
                                optionLabel='label'
                                :options='dd_companies'
                                option-value='id'
                                :filter='true'
                                filterPlaceholder='Cerca' />
                            <label for='company_id'>Azienda</label>
                        </span>
                    </div>
                </div>
                <div class='field col-6' v-if='teams.length > 0'>
                    <div class='p-inputgroup'>
                        <Button :icon='iconLoad' class='p-button-warning'
                                @click='bye' />
                        <span class='p-float-label'>
                            <Dropdown
                                v-model='model.team_id'
                                id='team_id'
                                dataKey='team_id'
                                optionLabel='label'
                                :options='teams'
                                option-value='id'
                                :filter='true'
                                filterPlaceholder='Cerca' />
                            <label for='team_id'>Team</label>
                        </span>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-6  md:col-3'>
                    <LanguageDropdown v-model='model.lang' placeholder='Lingua' />
                    <label class='sup'>Lingua utente</label>
                </div>

                <div class='field col-6  md:col-2'>
                    <BoolDropdown v-model='model.email_marketing' placeholder='Email Marketing' />
                    <label class='sup'>Email marketing</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-6  md:col-6'>
                    <span class='p-float-label'>
                        <PlansDropdown v-model='v$.model.plan_id.$model'
                                       :class="{'p-invalid': v$.model.plan_id.$errors.length}"
                                       placeholder='Piano' />
                        <label>Piano</label>
                    </span>
                </div>
            </div>

            <h3>Formato del file <u>con</u> riga di intestazione e virgola "," separatore di campo</h3>
            <Button type='button' label='Nome' class='mr-2' />
            <Button type='button' label='Cognome *' class='mr-2' />
            <Button type='button' label='Email *' class='mr-2' />
            <Button type='button' label='Durata gg [opz]' class='mr-2' />

            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12  md:col-6'>
                    <uppy-dashboard
                        :uppy='uppyCsv'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='file'
                                   type='text'
                                   v-model='model.file'
                                   :class="{'p-invalid': v$.model.file.$errors.length}"
                        />
                        <label for='file'>CSV</label>
                    </span>
                    <div v-if='model.file' class='text-center'>
                        <a :href='model.file'>Preview</a>
                    </div>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Tasks/ImportCompanySubscriptions');
const teamsStore = createNamespacedHelpers('Users/CompanyTeams');

import useVuelidate from '@vuelidate/core';

import model from './model';

import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import Uploader from '../../../mixins/uploader';
import LanguageDropdown from '../../../components/4books/forms/LanguageDropdown';
import BoolDropdown from '../../../components/4books/forms/BoolDropdown';
import PlansDropdown from '../../../components/4books/forms/PlansDropdown';
import dd_companies from '../../../mixins/dd_companies';
import tools from '../../../libs/tools';

export default {
    components: { LanguageDropdown, BoolDropdown, PlansDropdown },
    data() {
        return {
            model: null,
            teams: [],
            reloading: false,
        };
    },
    beforeUnmount() {
        this.resetUploads(model.uploads);
    },
    mounted() {
        this.model = this.row;
        this.prepareUploads(model.uploads, this.lang);
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return model.modelValidation;
    },
    computed: {
        ...mapGetters(['row', 'loading']),
        ...teamsStore.mapGetters(['rows']),
        iconLoad(){
            if (this.reloading) return "pi pi-spin pi-spinner";
            return "pi pi-sitemap";
        }
    },
    watch: {
        row(n) {
            this.model = n;
        },
        'model.company_id'(n) {
            this.model.team_id = null;
            if (!n) {
                this.model.company = '';
                this.teams = [];
                return;
            }
            this.reloadTeams(n);
            const company = this.getCompany(n);
            if (!company) {
                this.ShowError('Errore', 'Company non trovata');
                return;
            }
            this.model.company = company.slug;
        },
    },
    methods: {
        ...teamsStore.mapActions(['fetchList']),
        ...mapActions(['savePlain', 'createPlain']),
        ...mapMutations(['formIsDirty', 'formIsClean']),
        bye(){
            this.ShowSuccess('Dati aggiornati', 'L\'elenco dei teams è gia aggiornato');
        },
        reloadTeams(n){
            this.reloading = false;
            if (!n){
                return;
            }
            this.reloading = true;
            this.fetchList(n).then(items => {
                this.teams = tools.models.listsToDropdown(items);
            }).catch(err => {
                this.ShowStandardError(err.response);
            }).finally(() => {
                this.reloading = false;
            });
        },
        async save() {
            const result = await this.v$.$validate();
            if (!result) {
                this.ShowError('Errore', 'Alcuni campi del form non sono corretti');
                return;
            }

            const save = (this.model.id > 0) ? this.savePlain : this.createPlain;
            const changeRoute = (this.model.id === 0);
            this.model.company = this.sluggify(this.model.company);
            save(model.prepare(this.model)).then(() => {
                this.formIsClean();
                if (changeRoute) {
                    this.navigateToBase().then(() => {
                        this.ShowSuccess('Ottimo', 'Task creato con successo');
                    });
                    return;
                }
                this.ShowSuccess('Ottimo', 'Task modificato con successo');

            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },

    },
    mixins: [
        Navigations,
        Notifications,
        Uploader,
        dd_companies,
    ],
};
</script>
