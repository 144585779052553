import tools from '../../../libs/tools';
import { required } from '@vuelidate/validators';
import http from '../../../libs/http';

const model = {
    id: 0,
    type: 'Load-Manual-Subscriptions',
    created_at: null,
    file: '',
    runs: 0,
    started_at: null,
    stopped_at: null,
    multi: false,
    has_error: false,
    error: '',
    options: '{}',
    status: 0,
    lang: 'it',
    email_marketing: true,
    plan_id: null,
    company: '',
    company_id: 0,
    team_id: null
};

const translation = {};

export default {
    ...tools.models.apiCommons(model, translation, ''),
    prepare(model) {
        const copy = { ...model };
        const options = {
            lang: model.lang,
            company: model.company,
            company_id: parseInt(model.company_id),
            email_marketing: model.email_marketing,
            plan_id: model.plan_id,
        };
        if (model.team_id){
            options.team_id = parseInt(model.team_id);
        }
        copy.options = JSON.stringify(options);
        return copy;
    },
    modelValidation: {
        model: {
            file: {
                required,
            },
            company: {
                required,
            },
            company_id: {
                required,
            },
            plan_id: {
                required,
            },
        },
    },
    translationValidation: {},
    uploads: [
        {
            el: 'uppyCsv',
            target: 'file',
            folder: '/jobs/%Y/%M',
            protect: false,
            maxNum: 1,
            types: [tools.models.uploadFileTypes.CSV],
            urlChanger: url => {
                return url.replace(http.api.getS3bucket(), '');
            },
        },
    ],
};
